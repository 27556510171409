import React, { FC, useCallback, useEffect } from 'react'
import { Button, Modal } from 'react-bulma-components'
import { useNavigate, useParams } from 'react-router-dom'

import { Paragraph } from '../Paragraph'
import {} from '../Text'

import { useLazyFetch } from '../../hooks'
import { deleteTodoItemReq } from '../../requests/todo-item'
import { TodoItem } from '../../types/dto/todo-item'

type TodoItemDeleteModalProps = {
  item: TodoItem
  onClose: () => void
  onRefetch: () => void
  show: boolean
}

const TodoItemDeleteModal: FC<TodoItemDeleteModalProps> = (props) => {
  const { item, onClose, onRefetch, show } = props
  const { itemId } = item

  const navigate = useNavigate()
  const params = useParams<{ itemId: string }>()

  const deleteFetch = useLazyFetch<never>()

  const onSubmit = useCallback(() => {
    if (deleteFetch.state === 'idle') {
      deleteFetch.fetch(...deleteTodoItemReq(itemId))
    }
  }, [deleteFetch, itemId])

  useEffect(() => {
    if (deleteFetch.state === 'success') {
      deleteFetch.reset()
      onClose()
      onRefetch()

      if (params.itemId && params.itemId === item.itemId) {
        navigate('/todo', { replace: true })
      }
    }
  }, [deleteFetch, item, navigate, onClose, onRefetch, params])

  const loading = deleteFetch.state === 'loading'

  return (
    <Modal onClose={onClose} show={show} showClose={false}>
      <Modal.Card>
        <Modal.Card.Header>
          <Modal.Card.Title>Poista kohde</Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <Paragraph>
            Olet poistamassa kohteen <strong>{item.title}</strong>.
          </Paragraph>
          <Paragraph className="mt-5">Haluatko varmasti poistaa kohteen?</Paragraph>
        </Modal.Card.Body>
        <Modal.Card.Footer>
          <Button color="info" onClick={onSubmit} disabled={loading} loading={loading ?? undefined}>
            Poista
          </Button>
          <Button color="white-bis" onClick={onClose}>
            Peruuta
          </Button>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
}

export { TodoItemDeleteModal }
