import * as constants from './constants'
import { Token } from '../types/dto/auth'
import { User } from '../types/dto/user'
import { UpdateTokenAction, UpdateUserAction } from '../types/store'

const createUpdateToken = (token: Token): UpdateTokenAction => {
  return {
    type: constants.UPDATE_TOKEN,
    payload: token,
  }
}

const createUpdateUser = (user: User): UpdateUserAction => {
  return {
    type: constants.UPDATE_USER,
    payload: user,
  }
}

export {
  // prettier-ignore
  createUpdateToken,
  createUpdateUser,
}
