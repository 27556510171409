import React, { FC, useEffect } from 'react'
import { Block, Container, Heading } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFish } from '@fortawesome/free-solid-svg-icons'

import { initConfig } from '../../config'
import { Footer } from '../../components/Footer'
import { mkAuthUrl } from '../../utils/auth'

const LandingPage: FC = () => {
  const config = initConfig()

  useEffect(() => {
    document.title = 'Muikku'
    document.documentElement.lang = 'fi'
  }, [])

  return (
    <>
      <header>
        <Container breakpoint="max">
          <Block className="pt-4 pb-4" display="flex" alignItems="center" justifyContent="center">
            <FontAwesomeIcon icon={faFish} style={{ fontSize: '20rem' }} />
          </Block>
        </Container>
      </header>
      <main>
        <Container breakpoint="max">
          <Block display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={6}>
            <Heading
              className="is-family-mont-serrat has-text-weight-bold"
              style={{ color: 'hsl(0, 0%, 30%)', fontSize: '5rem' }}
            >
              Muikku
            </Heading>
            <a
              className="mt-5 px-5 py-3 has-background-info has-text-white is-size-4"
              href={mkAuthUrl(config)}
              style={{ borderRadius: '4px' }}
            >
              Kirjaudu sisään
            </a>
          </Block>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export { LandingPage }
