import React, { FC } from 'react'
import { Container } from 'react-bulma-components'
import { Outlet } from 'react-router-dom'

import { Footer } from '../Footer'
import { Header } from '../Header'

const Layout: FC = () => {
  return (
    <>
      <Header />
      <main>
        <Container breakpoint="fullhd">
          <Outlet />
        </Container>
      </main>
      <Footer />
    </>
  )
}

export { Layout }
