import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bulma-components'

import { useLazyFetch } from '../../hooks'
import { createTodoItemReq } from '../../requests/todo-item'
import { CreateTodoItemBody } from '../../types/dto/todo-item'

type TodoItemCreateRootModalProps = {
  onClose: () => void
  onRefetch: () => void
  show: boolean
}

const TodoItemCreateRootModal: FC<TodoItemCreateRootModalProps> = (props) => {
  const { onClose, onRefetch, show } = props

  const createFetch = useLazyFetch<never>()

  const [titleTouched, setTitleTouched] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const onTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitleTouched(true)
    setTitle(e.target.value)
  }, [])

  const onDescriptionChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value)
  }, [])

  const onSubmit = useCallback(() => {
    if (createFetch.state === 'idle') {
      const data: CreateTodoItemBody = { parentId: null, title, description: description || null }
      createFetch.fetch(...createTodoItemReq(data))
    }
  }, [description, createFetch, title])

  const onReset = useCallback(() => {
    setTitleTouched(false)
    setTitle('')
    setDescription('')
  }, [])

  useEffect(() => {
    if (createFetch.state === 'success') {
      createFetch.reset()
      onClose()
      onReset()
      onRefetch()
    }
  }, [createFetch, onClose, onRefetch, onReset])

  const loading = createFetch.state === 'loading'
  const emptyTitle = title === ''

  return (
    <Modal onClose={onClose} show={show} showClose={false}>
      <Modal.Card>
        <Modal.Card.Header>
          <Modal.Card.Title>Lisää uusi kohde</Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <form>
            <Form.Field>
              <Form.Label>Otsikko</Form.Label>
              <Form.Control>
                <Form.Input color="info" value={title} onChange={onTitleChange} />
              </Form.Control>
              {titleTouched && !title && <Form.Help color="danger">Tämä tietue on pakollinen.</Form.Help>}
            </Form.Field>

            <Form.Field>
              <Form.Label>Kuvaus</Form.Label>
              <Form.Textarea color="info" value={description} onChange={onDescriptionChange} />
            </Form.Field>
          </form>
        </Modal.Card.Body>
        <Modal.Card.Footer>
          <Button color="info" onClick={onSubmit} disabled={loading || emptyTitle} loading={loading ?? undefined}>
            Tallenna
          </Button>
          <Button color="white-bis" onClick={onClose}>
            Peruuta
          </Button>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
}

export { TodoItemCreateRootModal }
