import React, { FC } from 'react'
import { Notification } from 'react-bulma-components'
import { Paragraph } from '../Paragraph'

const ErrorNotification: FC = () => {
  return (
    <Notification color="warning" light>
      <Paragraph>Sivulla tapahtui virhe! Yritä myöhemmin uudestaan.</Paragraph>
    </Notification>
  )
}

export { ErrorNotification }
