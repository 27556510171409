import { initConfig } from '../config'
import { get } from '../utils/requests'

const config = initConfig()

const getTokenReq = (): [RequestInfo, RequestInit] => {
  const url = new URL(`${config.api.baseUrl}auth/introspect`)
  const info: RequestInfo = url.toString()
  const init: RequestInit = get()
  return [info, init]
}

export { getTokenReq }
