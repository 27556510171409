import React, { FC, useCallback, useMemo, useEffect, useState } from 'react'
import { Block, Button, Breadcrumb, Heading, Level } from 'react-bulma-components'
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faTrashCan, faShare } from '@fortawesome/free-solid-svg-icons'

import { ErrorNotification } from '../../components/ErrorNotification'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Text } from '../../components/Text'
import { TodoItemCreateChildModal } from '../../components/Todo/TodoItemCreateChildModal'
import { TodoItemDeleteModal } from '../../components/Todo/TodoItemDeleteModal'
import { TodoItemUpdateModal } from '../../components/Todo/TodoItemUpdateModal'
import { TodoItemShareModal } from '../../components/Todo/TodoItemShareModal'
import { TodoItemsTree } from '../../components/Todo/TodoItemsTree'

import { useFetch } from '../../hooks'
import { getTodoItemNodeReq } from '../../requests/todo-item-node'
import { TodoItem } from '../../types/dto/todo-item'
import { GetTodoItemNodeResult } from '../../types/dto/todo-item-node'
import { TodoSelectDataItem } from '../../types/props/todo-item'
import { flattenNode } from '../../utils/todo'

const TodoItemPage: FC = () => {
  const { itemId } = useParams() as { itemId: string }
  const nodeFetch = useFetch<GetTodoItemNodeResult>(...getTodoItemNodeReq(itemId, 5))

  const [itemToCreate, setItemToCreate] = useState<TodoItem | undefined>()
  const [itemToUpdate, setItemToUpdate] = useState<TodoItem | undefined>()
  const [itemToDelete, setItemToDelete] = useState<TodoItem | undefined>()
  const [itemToShare, setItemToShare] = useState<TodoItem | undefined>()

  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)

  const onCloseCreateModal = useCallback(() => setShowCreateModal(false), [])
  const onCloseUpdateModal = useCallback(() => setShowUpdateModal(false), [])
  const onCloseDeleteModal = useCallback(() => setShowDeleteModal(false), [])
  const onCloseShareModal = useCallback(() => setShowShareModal(false), [])

  const onItemCreate = useCallback((item: TodoItem) => {
    setItemToCreate(item)
    setShowCreateModal(true)
  }, [])

  const onItemUpdate = useCallback((item: TodoItem) => {
    setItemToUpdate(item)
    setShowUpdateModal(true)
  }, [])

  const onItemDelete = useCallback((item: TodoItem) => {
    setItemToDelete(item)
    setShowDeleteModal(true)
  }, [])

  const onItemShare = useCallback((item: TodoItem) => {
    setItemToShare(item)
    setShowShareModal(true)
  }, [])

  const onRefetch = useCallback(() => {
    if (nodeFetch.state === 'idle') {
      nodeFetch.refetch()
    }
  }, [nodeFetch])

  useEffect(() => {
    document.documentElement.lang = 'fi'
  }, [])

  useEffect(() => {
    if (nodeFetch.state === 'success') {
      document.title = `${nodeFetch.data.node.item.title} - Muikku`
      nodeFetch.reset()
    }
  }, [nodeFetch])

  const loading = nodeFetch.state === 'loading'
  const error = nodeFetch.state === 'error'
  const success = nodeFetch.state === 'success' || nodeFetch.state === 'idle'

  const node = success ? nodeFetch.data?.node : undefined
  const item = node?.item

  const itemsSelect = useMemo(() => {
    const itemsFlat = node ? flattenNode(node) : []
    return itemsFlat.map((x): TodoSelectDataItem => ({ itemId: x.itemId, title: x.title }))
  }, [node])

  return (
    <Block className="mt-3">
      <Breadcrumb separator="succeeds">
        <Breadcrumb.Item>
          <Link to="/todo">Etusivu</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/todo/items">Todo-kohteet</Link>
        </Breadcrumb.Item>
        {item && (
          <Breadcrumb.Item active>
            <Link to={`/todo/items/${item.itemId}`}>{item.title}</Link>
          </Breadcrumb.Item>
        )}
      </Breadcrumb>

      {loading && <LoadingSpinner />}
      {error && <ErrorNotification />}

      {node && item && (
        <Block className="mt-2r pb-6">
          <Level renderAs="div">
            <Level.Side align="left">
              <Block>
                <Heading>{item.title}</Heading>
                {item.description && <Heading subtitle>{item.description}</Heading>}
              </Block>
            </Level.Side>
            <Level.Side align="right" className="mr-4">
              <Button.Group>
                <Button color="info" onClick={() => onItemCreate(item)}>
                  <FontAwesomeIcon icon={faPlus} />
                  <Text className="ml-2">Lisää</Text>
                </Button>
                <Button color="info" onClick={() => onItemUpdate(item)}>
                  <FontAwesomeIcon icon={faEdit} />
                  <Text className="ml-2">Muokkaa</Text>
                </Button>
                <Button color="info" onClick={() => onItemDelete(item)}>
                  <FontAwesomeIcon icon={faTrashCan} />
                  <Text className="ml-2">Poista</Text>
                </Button>
                <Button color="info" onClick={() => onItemShare(item)}>
                  <FontAwesomeIcon icon={faShare} />
                  <Text className="ml-2">Jaa</Text>
                </Button>
              </Button.Group>
            </Level.Side>
          </Level>
          <Block className="mt-3">
            <TodoItemsTree
              nodes={node.children}
              onCreateItem={onItemCreate}
              onDeleteItem={onItemDelete}
              onUpdateItem={onItemUpdate}
            />
          </Block>
        </Block>
      )}

      {itemToCreate && (
        <TodoItemCreateChildModal
          initialParentId={itemToCreate.itemId}
          items={itemsSelect}
          onClose={onCloseCreateModal}
          onRefetch={onRefetch}
          show={showCreateModal}
        />
      )}

      {itemToUpdate && (
        <TodoItemUpdateModal
          item={itemToUpdate}
          onClose={onCloseUpdateModal}
          onRefetch={onRefetch}
          show={showUpdateModal}
        />
      )}

      {itemToDelete && (
        <TodoItemDeleteModal
          item={itemToDelete}
          onClose={onCloseDeleteModal}
          onRefetch={onRefetch}
          show={showDeleteModal}
        />
      )}

      {itemToShare && (
        <TodoItemShareModal
          item={itemToShare}
          onClose={onCloseShareModal}
          onRefetch={onRefetch}
          show={showShareModal}
        />
      )}
    </Block>
  )
}

export { TodoItemPage }
