import { initConfig } from '../config'
import { get } from '../utils/requests'

const config = initConfig()

const getUserReq = (): [RequestInfo, RequestInit] => {
  const url = new URL(`${config.api.baseUrl}users/me`)
  const info: RequestInfo = url.toString()
  const init: RequestInit = get()
  return [info, init]
}

const getUsersReq = (pageSize?: number, pageToken?: string): [RequestInfo, RequestInit] => {
  const url = new URL(`${config.api.baseUrl}users`)

  if (pageSize) url.searchParams.append('pageSize', pageSize.toString())
  if (pageToken) url.searchParams.append('pageToken', pageToken)

  const info: RequestInfo = url.toString()
  const init: RequestInit = get()

  return [info, init]
}

export { getUserReq, getUsersReq }
