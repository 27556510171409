import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { legacy_createStore as createStore } from 'redux'

import 'bulma/css/bulma.min.css'
import './index.css'

import { App } from './App'
import { reducer } from './store/reducer'

const store = createStore(reducer)
const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
