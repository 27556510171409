import { Config } from '../types/config'

function initConfig(): Config {
  // prettier-ignore
  const requiredVars = [
    'REACT_APP_API_BASE_URL',
    'REACT_APP_AUTH_BASE_URL',
    'REACT_APP_AUTH_CLIENT_ID',
  ]

  const missingErrors = checkEnvVars(Object.keys(process.env), requiredVars)

  if (missingErrors.length > 0) {
    throw new Error(missingErrors.map((x) => x.message).join('\n'))
  }

  const env = process.env as Record<string, string | undefined>

  return {
    api: {
      baseUrl: new URL(env.REACT_APP_API_BASE_URL as string),
    },
    auth: {
      baseUrl: new URL(env.REACT_APP_AUTH_BASE_URL as string),
      clientId: env.REACT_APP_AUTH_CLIENT_ID as string,
    },
  }
}

const checkEnvVars = (actualVars: string[], expectedVars: string[]): Error[] => {
  return expectedVars
    .filter((variable) => !actualVars.includes(variable))
    .map((variable) => new Error(`Missing environment variable: ${variable}`))
}

export { initConfig }
