import React, { FC } from 'react'
import { Block, Columns, Container } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFish } from '@fortawesome/free-solid-svg-icons'

import { Text } from '../Text'

const Footer: FC = () => {
  return (
    <footer className="has-background-white-ter">
      <Container breakpoint="max">
        <Block style={{ padding: '3.5rem 0' }}>
          <Columns vCentered>
            <Columns.Column narrow>
              <FontAwesomeIcon icon={faFish} className="has-text-grey-dark is-size-1" />
            </Columns.Column>
            <Columns.Column>
              <Text className="has-text-grey-darker has-text-weight-light is-size-6">
                Muikku (Coregonus albula) on siikojen sukuun (Coregonus) kuuluva 5–20 senttimetriä pitkä lohikala, joka
                elää parvina Euroopan pohjoisosan järvissä ja Itämeren vähäsuolaisissa reunaosissa. Tämä pieni lohikala
                on Suomen sisävesien ammattikalastajien tärkein saaliskala. Muikkua pyydystetään troolilla, nuotalla,
                rysällä ja pienisilmäisellä muikkuverkolla.
              </Text>
            </Columns.Column>
          </Columns>
        </Block>
      </Container>
    </footer>
  )
}

export { Footer }
