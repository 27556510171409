import qs from 'qs'
import { getAccessToken } from './storage'
import { Config } from '../types/config'

const mkAuthUrl = (config: Config): string => {
  const { baseUrl, clientId } = config.auth
  const { host, protocol } = window.location

  const searchParams = {
    client_id: clientId,
    response_type: 'token',
    scope: 'aws.cognito.signin.user.admin+email+openid+phone+profile',
    redirect_uri: `${protocol}//${host}/login`,
  }

  const queryString = qs.stringify(searchParams, { encode: false })

  return `${baseUrl}login?${queryString}`
}

const mkAuthHeader = (): string | undefined => {
  const accessToken = getAccessToken()
  return accessToken && accessToken.length ? `Bearer ${accessToken}` : undefined
}

export { mkAuthUrl, mkAuthHeader }
