import cn from 'classnames'
import React, { FC, useCallback, useState } from 'react'
import { Container, Navbar } from 'react-bulma-components'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFish, faUser, faDoorOpen } from '@fortawesome/free-solid-svg-icons'

import { Text } from '../Text'
import { User } from '../../types/dto/user'
import { StoreState } from '../../types/store'

const LinkStyled = styled(Link)`
  color: hsl(0, 0%, 21%);

  &:hover {
    color: hsl(217, 71%, 53%);
  }
`

const ItemStyled = styled(Navbar.Item)`
  &:hover {
    color: inherit !important;
  }
`

const Header: FC = () => {
  const user = useSelector<StoreState, User | undefined>((state) => state.user)
  const [userMenuActive, setUserMenuActive] = useState(false)

  const toggleUserMenu = useCallback(() => {
    setUserMenuActive(!userMenuActive)
  }, [userMenuActive])

  return (
    <header>
      <Container breakpoint="max" className="pt-4 pb-4">
        <Navbar transparent>
          <Navbar.Brand>
            <ItemStyled hoverable={false}>
              <FontAwesomeIcon icon={faFish} style={{ fontSize: '3.75rem' }} />
              <Text className="ml-3 is-size-2 is-family-mont-serrat has-text-weight-bold">Muikku</Text>
            </ItemStyled>
            <Navbar.Burger
              className={cn('is-align-self-center', { 'is-active': userMenuActive })}
              onClick={toggleUserMenu}
            />
          </Navbar.Brand>
          {user && (
            <Navbar.Menu className={cn('is-align-self-center', { 'is-active': userMenuActive })}>
              <Navbar.Container align="right">
                <Navbar.Item active={userMenuActive} onClick={toggleUserMenu}>
                  <Navbar.Link>{user.name}</Navbar.Link>
                  <Navbar.Dropdown boxed className="is-right">
                    <Navbar.Item renderAs="div">
                      <LinkStyled to="/todo/user">
                        <FontAwesomeIcon icon={faUser} style={{ fontSize: '1.05rem' }} />
                        <Text style={{ marginLeft: '1ch' }}>Omat tiedot</Text>
                      </LinkStyled>
                    </Navbar.Item>
                    <Navbar.Item renderAs="div">
                      <LinkStyled to="/logout">
                        <FontAwesomeIcon icon={faDoorOpen} style={{ fontSize: '1rem' }} />
                        <Text style={{ marginLeft: '1ch' }}>Kirjaudu ulos</Text>
                      </LinkStyled>
                    </Navbar.Item>
                  </Navbar.Dropdown>
                </Navbar.Item>
              </Navbar.Container>
            </Navbar.Menu>
          )}
        </Navbar>
      </Container>
    </header>
  )
}

export { Header }
