import React, { FC } from 'react'
import { Block } from 'react-bulma-components'
import { Navigate, useLocation } from 'react-router-dom'
import { setAccessToken } from '../../utils/storage'

const Login: FC = () => {
  const location = useLocation()
  const hashParams = new URLSearchParams(location.hash.replace(/^#/, ''))
  const accessToken = hashParams.get('access_token')

  if (!accessToken) {
    return <Block>Sisäänkirjautuminen epäonnistui.</Block>
  }

  setAccessToken(accessToken)

  return <Navigate to="/todo" />
}

export { Login }
