import React, { FC, useEffect } from 'react'
import { Block, Breadcrumb, Heading } from 'react-bulma-components'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Text } from '../../components/Text'
import { User } from '../../types/dto/user'
import { StoreState } from '../../types/store'

const UserPage: FC = () => {
  const user = useSelector<StoreState, User | undefined>((state) => state.user)

  useEffect(() => {
    if (user) document.title = `${user.name} - Muikku`
    document.documentElement.lang = 'fi'
  }, [user])

  const details = user
    ? [
        { label: 'Nimi', value: user.name },
        { label: 'Sähköposti', value: user.email },
      ]
    : []

  return (
    <Block className="mt-3">
      <Breadcrumb separator="succeeds">
        <Breadcrumb.Item>
          <Link to="/todo">Etusivu</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link to="/todo/user">Omat tiedot</Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      {user && (
        <Block className="mt-2r pb-6">
          <Heading>Omat tiedot</Heading>
          <ul>
            {details.map((item, index) => (
              <li key={index}>
                <Block display="flex" flexDirection="row" justifyContent="space-between" style={{ width: '75ch' }}>
                  <Text className="is-size-5">{item.label}</Text>
                  <Text className="is-size-5">{item.value}</Text>
                </Block>
              </li>
            ))}
          </ul>
        </Block>
      )}
    </Block>
  )
}

export { UserPage }
