import React, { CSSProperties, FC, ReactNode } from 'react'

type TextProps = {
  children: ReactNode
  className?: string
  style?: CSSProperties
}

const Text: FC<TextProps> = (props) => {
  const { children, className = '', style } = props

  return (
    <span className={className} style={style}>
      {children}
    </span>
  )
}

export { Text }
