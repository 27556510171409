import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { Block, Button, Form, Modal } from 'react-bulma-components'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faRemove } from '@fortawesome/free-solid-svg-icons'

import { LoadingSpinner } from '../LoadingSpinner'
import { Paragraph } from '../Paragraph'
import { Text } from '../Text'

import { useFetch, useLazyFetch } from '../../hooks'
import { getItemAccessesReq, upsertItemAccessesReq } from '../../requests/todo-item-access'
import { getUsersReq } from '../../requests/user'
import { TodoItem } from '../../types/dto/todo-item'
import { GetTodoItemAccessesResult } from '../../types/dto/todo-item-access'
import { GetUsersResult } from '../../types/dto/user'
import { SelectItem } from '../../types/props/select'
import { TodoAccessDataItem } from '../../types/props/todo-access'

const ModalStyled = styled(Modal)`
  .modal-card {
    width: 854px;
  }
`

const noneItem: SelectItem = {
  value: '',
  text: 'Ei valittu',
}

const accessTypeItems: SelectItem[] = [
  noneItem,
  { value: 'ReadWrite', text: 'Katselu ja muokkaus' },
  { value: 'ReadOnly', text: 'Vain katselu' },
]

const accessItemSlug: TodoAccessDataItem = {
  userId: noneItem.value,
  accessType: noneItem.value,
}

type TodoItemShareModalProps = {
  item: TodoItem
  onClose: () => void
  onRefetch: () => void
  show: boolean
}

const TodoItemShareModal: FC<TodoItemShareModalProps> = (props) => {
  const { item, onClose, onRefetch, show } = props
  const { itemId } = item
  const userId = localStorage.getItem('user_id')

  const accessesFetch = useFetch<GetTodoItemAccessesResult>(...getItemAccessesReq(itemId))
  const usersFetch = useFetch<GetUsersResult>(...getUsersReq())
  const upsertFetch = useLazyFetch<never>()

  const [accessItems, setAccessItems] = useState<TodoAccessDataItem[]>([accessItemSlug])
  const [userItems, setUserItems] = useState<SelectItem[]>([noneItem])

  const onUserChange = useCallback(
    (index: number) => (e: ChangeEvent<HTMLSelectElement>) => {
      const newAccessItems = accessItems.map((x) => x)
      newAccessItems[index] = { ...newAccessItems[index], userId: e.target.value }
      setAccessItems(newAccessItems)
    },
    [accessItems],
  )

  const onAccessTypeChange = useCallback(
    (index: number) => (e: ChangeEvent<HTMLSelectElement>) => {
      const newAccessItems = accessItems.map((x) => x)
      newAccessItems[index] = { ...newAccessItems[index], accessType: e.target.value }
      setAccessItems(newAccessItems)
    },
    [accessItems],
  )

  const onSubmit = useCallback(() => {
    if (upsertFetch.state === 'idle') {
      const accesses = accessItems
        .filter((x) => x.userId.length && x.accessType.length)
        .map((x) => ({ itemId, userId: x.userId, accessType: x.accessType }))

      upsertFetch.fetch(...upsertItemAccessesReq(itemId, accesses))
    }
  }, [accessItems, itemId, upsertFetch])

  const onAddAccessItemRow = useCallback(() => {
    setAccessItems([...accessItems, accessItemSlug])
  }, [accessItems])

  const onDeleteAccessItemRow = useCallback(
    (index: number) => () => {
      setAccessItems(accessItems.filter((_, i) => i !== index))
    },
    [accessItems],
  )

  useEffect(() => {
    if (upsertFetch.state === 'success') {
      upsertFetch.reset()
      if (accessesFetch.state === 'success') accessesFetch.reset()
      onClose()
      onRefetch()
    }
  }, [accessesFetch, upsertFetch, onClose, onRefetch])

  useEffect(() => {
    if (accessesFetch.state === 'idle') {
      accessesFetch.refetch()
    }

    if (accessesFetch.state === 'success' && accessesFetch.data.accesses.length) {
      const fetchedItems = accessesFetch.data.accesses.map((access) => ({
        userId: access.userId,
        accessType: access.accessType,
      }))

      setAccessItems([...fetchedItems, accessItemSlug])
    }
  }, [accessesFetch])

  useEffect(() => {
    if (usersFetch.state === 'success' && usersFetch.data.users.length) {
      const fetchedUsers = usersFetch.data.users.map((user) => ({
        value: user.userId,
        text: `${user.name} <${user.email}>`,
      }))

      setUserItems([...userItems, ...fetchedUsers])
      usersFetch.reset()
    }

    if (usersFetch.state === 'idle' && usersFetch.data?.pageToken) {
      usersFetch.refetch(...getUsersReq(20, usersFetch.data.pageToken))
    }
  }, [item, userId, userItems, usersFetch])

  const loading = accessesFetch.state === 'loading' || usersFetch.state === 'loading'
  const submitting = upsertFetch.state === 'loading'

  return (
    <ModalStyled onClose={onClose} show={show} showClose={false}>
      <Modal.Card>
        <Modal.Card.Header>
          <Modal.Card.Title>Jaa kohde</Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <Paragraph>
            Valitse käyttäjät, joille haluat antaa oikeuden katsella tai muokata tämän kohteen tietoja.
          </Paragraph>
          {loading ? (
            <Block mt={5}>
              <LoadingSpinner />
            </Block>
          ) : (
            <Block mt={5}>
              <form>
                {accessItems.map((accessItem, index) => (
                  <Form.Field kind="group" key={index}>
                    <Form.Field>
                      <Form.Label>Käyttäjä</Form.Label>
                      <Form.Control>
                        <Form.Select color="info" onChange={onUserChange(index)} value={accessItem.userId}>
                          {userItems.map((userItem) => (
                            <option key={userItem.value} value={userItem.value}>
                              {userItem.text}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Control>
                    </Form.Field>

                    <Form.Field ml={3}>
                      <Form.Label>Oikeudet</Form.Label>
                      <Form.Control>
                        <Form.Select color="info" value={accessItem.accessType} onChange={onAccessTypeChange(index)}>
                          {accessTypeItems.map((accessTypeItem) => (
                            <option key={accessTypeItem.value} value={accessTypeItem.value}>
                              {accessTypeItem.text}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Control>
                    </Form.Field>

                    <Form.Field ml={3}>
                      <Form.Label hidden invisible>
                        &nbsp;
                      </Form.Label>
                      <Form.Control>
                        <Button type="button" color="info" inverted onClick={onDeleteAccessItemRow(index)}>
                          <FontAwesomeIcon icon={faRemove} />
                          <Text className="ml-2">Poista rivi</Text>
                        </Button>
                      </Form.Control>
                    </Form.Field>
                  </Form.Field>
                ))}
              </form>
              <Block mt={4}>
                <Button color="info" inverted onClick={onAddAccessItemRow}>
                  <FontAwesomeIcon icon={faPlus} />
                  <Text className="ml-2">Lisää rivi</Text>
                </Button>
              </Block>
            </Block>
          )}
        </Modal.Card.Body>
        <Modal.Card.Footer>
          <Button color="info" onClick={onSubmit} loading={submitting ?? undefined}>
            Tallenna
          </Button>
          <Button type="submit" color="white-bis" onClick={onClose}>
            Peruuta
          </Button>
        </Modal.Card.Footer>
      </Modal.Card>
    </ModalStyled>
  )
}

export { TodoItemShareModal }
