import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bulma-components'

import { useLazyFetch } from '../../hooks'
import { updateTodoItemReq } from '../../requests/todo-item'
import { TodoItem, UpdateTodoItemBody } from '../../types/dto/todo-item'

type TodoItemUpdateModalProps = {
  item: TodoItem
  onClose: () => void
  onRefetch: () => void
  show: boolean
}

const TodoItemUpdateModal: FC<TodoItemUpdateModalProps> = (props) => {
  const { item, onClose, onRefetch, show } = props
  const { itemId } = item

  const updateFetch = useLazyFetch<never>()

  const [title, setTitle] = useState(item.title)
  const [description, setDescription] = useState(item.description)
  const [titleTouched, setTitleTouched] = useState(false)

  const onTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitleTouched(true)
    setTitle(e.target.value)
  }, [])

  const onDescriptionChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value)
  }, [])

  const onSubmit = useCallback(() => {
    if (updateFetch.state === 'idle') {
      const data: UpdateTodoItemBody = { title, description: description || null }
      updateFetch.fetch(...updateTodoItemReq(itemId, data))
    }
  }, [description, itemId, title, updateFetch])

  useEffect(() => {
    if (updateFetch.state === 'success') {
      updateFetch.reset()
      onClose()
      onRefetch()
    }
  }, [updateFetch, onClose, onRefetch])

  useEffect(() => {
    setTitle(item.title)
    setDescription(item.description)
    setTitleTouched(false)
  }, [item])

  const loading = updateFetch.state === 'loading'
  const noTitle = title === ''

  return (
    <Modal onClose={onClose} show={show} showClose={false}>
      <Modal.Card>
        <Modal.Card.Header>
          <Modal.Card.Title>Muokkaa kohdetta</Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <form>
            <Form.Field>
              <Form.Label>Otsikko</Form.Label>
              <Form.Control>
                <Form.Input value={title} onChange={onTitleChange} />
              </Form.Control>
              {titleTouched && !title && <Form.Help color="danger">Tämä tietue on pakollinen.</Form.Help>}
            </Form.Field>

            <Form.Field>
              <Form.Label>Kuvaus</Form.Label>
              <Form.Textarea value={description ?? undefined} onChange={onDescriptionChange} />
            </Form.Field>
          </form>
        </Modal.Card.Body>
        <Modal.Card.Footer>
          <Button color="info" onClick={onSubmit} disabled={loading || noTitle} loading={loading ?? undefined}>
            Tallenna
          </Button>
          <Button color="white-bis" onClick={onClose}>
            Peruuta
          </Button>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
}

export { TodoItemUpdateModal }
