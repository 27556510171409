import React, { FC, useCallback, useEffect } from 'react'
import { Block, Button, Container, Heading, Notification } from 'react-bulma-components'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFish } from '@fortawesome/free-solid-svg-icons'

import { Footer } from '../../components/Footer'
import { Paragraph } from '../../components/Paragraph'

const NotFoundPage: FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Sivua ei löytynyt'
    document.documentElement.lang = 'fi'
  }, [])

  const navigateBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <>
      <header>
        <Container breakpoint="max">
          <Block className="pt-4 pb-4" display="flex" alignItems="center" justifyContent="center">
            <FontAwesomeIcon icon={faFish} style={{ fontSize: '10rem' }} />
          </Block>
        </Container>
      </header>
      <main>
        <Container breakpoint="max">
          <Block display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Heading>Tapahtui virhe</Heading>
            <Notification color="danger" light>
              <Paragraph>Hakemaasi sivua ei löytynyt. Tarkista, että osoite on kirjoitettu oikein.</Paragraph>
            </Notification>
            <Button color="info" onClick={navigateBack}>
              Mene takaisin
            </Button>
          </Block>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export { NotFoundPage }
