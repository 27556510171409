import { initConfig } from '../config'
import { UpsertTodoItemAccessBody } from '../types/dto/todo-item-access'
import { del, get, put } from '../utils/requests'

const config = initConfig()

export const deleteItemAccessReq = (itemId: string, userId: string): [RequestInfo, RequestInit] => {
  const info: RequestInfo = `${config.api.baseUrl}todo/items/access/${itemId}/${userId}`
  const init: RequestInit = del()
  return [info, init]
}

export const getItemAccessesReq = (itemId: string): [RequestInfo, RequestInit] => {
  const info: RequestInfo = `${config.api.baseUrl}todo/items/access/${itemId}`
  const init: RequestInit = get()
  return [info, init]
}

export const upsertItemAccessesReq = (
  itemId: string,
  accesses: UpsertTodoItemAccessBody[],
): [RequestInfo, RequestInit] => {
  const info: RequestInfo = `${config.api.baseUrl}todo/items/access/${itemId}`
  const init: RequestInit = put({ accesses })
  return [info, init]
}
