import { initConfig } from '../config'
import { get } from '../utils/requests'

const config = initConfig()

const getTodoItemNodeReq = (itemId: string, depth: number): [RequestInfo, RequestInit] => {
  const url = new URL(`${config.api.baseUrl}todo/items/nodes/${itemId}`)

  url.searchParams.append('depth', depth.toString())

  const info: RequestInfo = url.toString()
  const init: RequestInit = get()

  return [info, init]
}

const getTodoItemNodesReq = (parentId: string | undefined, depth: number): [RequestInfo, RequestInit] => {
  const url = new URL(`${config.api.baseUrl}todo/items/nodes`)

  if (parentId) url.searchParams.append('parentId', parentId)
  url.searchParams.append('depth', depth.toString())

  const info: RequestInfo = url.toString()
  const init: RequestInit = get()

  return [info, init]
}

export { getTodoItemNodeReq, getTodoItemNodesReq }
