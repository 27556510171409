const getAccessToken = (): string | null => {
  return localStorage.getItem('access_token')
}

const removeAccessToken = (): void => {
  localStorage.removeItem('access_token')
}

const setAccessToken = (accessToken: string): void => {
  localStorage.setItem('access_token', accessToken)
}

export { getAccessToken, removeAccessToken, setAccessToken }
