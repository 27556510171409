import { getAccessToken } from './storage'

const mkHeaders = (contentType?: string | undefined): Record<string, string> => {
  const headersMap = new Map<string, string>()
  const accessToken = getAccessToken()

  if (accessToken) {
    headersMap.set('Authorization', `Bearer ${accessToken}`)
  }

  if (contentType) {
    headersMap.set('Content-Type', contentType)
  }

  return Object.fromEntries(headersMap)
}

const del = (): RequestInit => {
  return {
    method: 'DELETE',
    headers: mkHeaders(),
  }
}

const get = (): RequestInit => {
  return {
    method: 'GET',
    headers: mkHeaders(),
  }
}

const patch = (body: Record<string, any>): RequestInit => {
  return {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: mkHeaders('application/json'),
  }
}

const post = (body: Record<string, any>): RequestInit => {
  return {
    method: 'POST',
    body: JSON.stringify(body),
    headers: mkHeaders('application/json'),
  }
}

const put = (body: Record<string, any>): RequestInit => {
  return {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: mkHeaders('application/json'),
  }
}

export { del, get, patch, post, put }
