import { initConfig } from '../config'
import { get } from '../utils/requests'

const config = initConfig()

export const getHealthReq = (): [RequestInfo, RequestInit] => {
  const url = new URL('/health', config.api.baseUrl)
  const info = url.toString()
  const init = get()
  return [info, init]
}
