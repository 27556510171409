import React, { FC, useEffect, useRef } from 'react'
import { Block } from 'react-bulma-components'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import { Layout } from './components/Layout'
import { Login } from './components/Login'
import { LoadingSpinner } from './components/LoadingSpinner'
import { Text } from './components/Text'

import { useLazyFetch } from './hooks'

import { ErrorPage } from './pages/ErrorPage'
import { ExpiredPage } from './pages/ExpiredPage'
import { LandingPage } from './pages/LandingPage'
import { LogoutPage } from './pages/LogoutPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { TodoMainPage } from './pages/TodoMainPage'
import { TodoItemPage } from './pages/TodoItemPage'
import { UserPage } from './pages/UserPage'

import { getTokenReq } from './requests/auth'
import { getUserReq } from './requests/user'

import * as actions from './store/actions'
import { Token } from './types/dto/auth'
import { User } from './types/dto/user'
import { StoreState } from './types/store'
import { removeAccessToken } from './utils/storage'
import { GetHealthResult } from './types/dto/health'
import { getHealthReq } from './requests/health'
import { useLazyEffect } from './hooks/use-lazy-effect'

const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path="/login" element={<Login />} />
        <Route path="/todo/*" element={<AuthorizedRoutes />} />
        <Route path="/user/*" element={<AuthorizedRoutes />} />

        <Route path="/error" element={<ErrorPage />} />
        <Route path="/expired" element={<ExpiredPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/not-found" element={<NotFoundPage />} />

        <Route path="*" element={<Navigate to="/not-found" replace />} />
      </Routes>
    </BrowserRouter>
  )
}

const AuthorizedRoutes: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const expireTimer = useRef<number | null>(null)

  const token = useSelector<StoreState, Token | undefined>((state) => state.token)
  const user = useSelector<StoreState, User | undefined>((state) => state.user)

  const fetchHealth = useLazyFetch<GetHealthResult>()
  const fetchToken = useLazyFetch<Token>()
  const fetchUser = useLazyFetch<User>()

  const healthStatus = fetchHealth.state === 'success' ? fetchHealth.data.status : undefined

  useLazyEffect(
    () => {
      if (fetchHealth.state === 'idle') {
        fetchHealth.fetch(...getHealthReq())
      }
    },
    [fetchHealth],
    5000,
  )

  useEffect(() => {
    if (fetchHealth.state === 'error') {
      fetchHealth.reset()
    }
  }, [fetchHealth])

  useEffect(() => {
    if (healthStatus !== 'ok') {
      return
    }

    if (!token && fetchToken.state === 'idle') {
      fetchToken.fetch(...getTokenReq())
    }

    if (!token && fetchToken.state === 'success') {
      dispatch(actions.createUpdateToken(fetchToken.data))
    }
  }, [healthStatus, token, dispatch, fetchToken])

  useEffect(() => {
    if (healthStatus !== 'ok') {
      return
    }

    if (!user && fetchUser.state === 'idle') {
      fetchUser.fetch(...getUserReq())
    }

    if (!user && fetchUser.state === 'success') {
      dispatch(actions.createUpdateUser(fetchUser.data))
    }
  }, [healthStatus, user, dispatch, fetchUser])

  useEffect(() => {
    if (token && expireTimer.current === null) {
      const timer = setInterval(() => {
        const now = Math.round(Date.now() / 1000)

        if (now > token.exp) {
          removeAccessToken()
          navigate('/expired')
        }
      }, 10000)

      expireTimer.current = timer as any
    }

    return () => {
      if (expireTimer.current) {
        clearTimeout(expireTimer.current)
        expireTimer.current = null
      }
    }
  }, [token, navigate])

  if (healthStatus !== 'ok') {
    return (
      <Block mx={4} my={4}>
        <LoadingSpinner />
        <Text style={{ marginLeft: '1ch' }}>Odotetaan resursseja...</Text>
      </Block>
    )
  }

  const loading = fetchToken.state === 'loading' || fetchUser.state === 'loading'
  const error = fetchToken.state === 'error' || fetchUser.state === 'error'

  if (loading) {
    return (
      <Block mx={4} my={4}>
        <LoadingSpinner />
        <Text style={{ marginLeft: '1ch' }}>Ladataan tietoja...</Text>
      </Block>
    )
  }

  if (error) {
    return <Navigate to="/error" />
  }

  if (!token || !user) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>
  }

  return (
    <Routes>
      <Route path="*" element={<Layout />}>
        <Route path="items" element={<TodoMainPage />} />
        <Route path="items/:itemId/*" element={<TodoItemPage />} />
        <Route path="user" element={<UserPage />} />
        <Route path="*" element={<Navigate to="/todo/items" replace />} />
      </Route>
    </Routes>
  )
}

export { App }
