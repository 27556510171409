import { initConfig } from '../config'
import { CreateTodoItemBody, UpdateTodoItemBody, OrderByKey } from '../types/dto/todo-item'
import { del, get, post, patch } from '../utils/requests'

const config = initConfig()

const createTodoItemReq = (data: CreateTodoItemBody): [RequestInfo, RequestInit] => {
  const info: RequestInfo = `${config.api.baseUrl}todo/items`
  const init: RequestInit = post(data)
  return [info, init]
}

const deleteTodoItemReq = (itemId: string): [RequestInfo, RequestInit] => {
  const info: RequestInfo = `${config.api.baseUrl}todo/items/${itemId}`
  const init: RequestInit = del()
  return [info, init]
}

const getTodoItemReq = (itemId: string): [RequestInfo, RequestInit] => {
  const info: RequestInfo = `${config.api.baseUrl}todo/items/${itemId}`
  const init: RequestInit = get()
  return [info, init]
}

const getTodoItemsReq = (
  pageNumber?: number,
  pageSize?: number,
  orderByKeys?: OrderByKey[],
): [RequestInfo, RequestInit] => {
  const url = new URL(`${config.api.baseUrl}todo/items`)

  if (pageNumber) url.searchParams.append('pageNumber', pageNumber.toString())
  if (pageSize) url.searchParams.append('pageSize', pageSize.toString())
  if (orderByKeys) orderByKeys.forEach((orderBy) => url.searchParams.append('orderBy', orderBy))

  const info: RequestInfo = url.toString()
  const init: RequestInit = get()

  return [info, init]
}

const updateTodoItemReq = (itemId: string, data: UpdateTodoItemBody): [RequestInfo, RequestInit] => {
  const info: RequestInfo = `${config.api.baseUrl}todo/items/${itemId}`
  const init: RequestInit = patch(data)
  return [info, init]
}

export {
  // prettier-ignore
  createTodoItemReq,
  deleteTodoItemReq,
  getTodoItemReq,
  getTodoItemsReq,
  updateTodoItemReq,
}
