import * as constants from './constants'
import { StoreAction, StoreState } from '../types/store'

export const initialState: StoreState = {
  token: undefined,
  user: undefined,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state: StoreState = initialState, action: StoreAction): StoreState => {
  switch (action.type) {
    case constants.UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload,
      }

    case constants.UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      }

    default:
      return { ...state }
  }
}

export { reducer }
