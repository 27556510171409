import React, { FC } from 'react'
import { Block, Button } from 'react-bulma-components'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons'

import { Text } from '../Text'
import { TodoItem } from '../../types/dto/todo-item'
import { TodoItemNode } from '../../types/dto/todo-item-node'

const Summary = styled.summary`
  button {
    border-width: 0px !important;
    display: none;
    height: 24px;
    padding: 0rem 0.2rem;
  }

  button:hover {
    color: #3e8ed0;
  }

  &:hover button {
    display: block;
  }
`

type TodoItemsTreeProps = {
  nodes: TodoItemNode[]
  onCreateItem: (item: TodoItem) => void
  onDeleteItem: (item: TodoItem) => void
  onUpdateItem: (item: TodoItem) => void
}

const TodoItemsTree: FC<TodoItemsTreeProps> = (props) => {
  const { nodes, onCreateItem, onDeleteItem, onUpdateItem } = props

  return (
    <ul className="ml-5">
      {nodes.map(({ children, item }) => (
        <li key={item.itemId}>
          <details className="ml-1" open>
            <Summary>
              <Block display="inline-flex" alignItems="center">
                <Text>{item.title}</Text>
                <Button.Group className="ml-3" size="small">
                  <Button onClick={() => onCreateItem(item)}>
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                  <Button onClick={() => onUpdateItem(item)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                  <Button onClick={() => onDeleteItem(item)}>
                    <FontAwesomeIcon icon={faTrashCan} />
                  </Button>
                </Button.Group>
              </Block>
            </Summary>
            <TodoItemsTree
              nodes={children}
              onCreateItem={onCreateItem}
              onDeleteItem={onDeleteItem}
              onUpdateItem={onUpdateItem}
            />
          </details>
        </li>
      ))}
    </ul>
  )
}

export { TodoItemsTree }
