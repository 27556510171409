import React, { FC, useCallback, useEffect, useState } from 'react'
import { Block, Button, Breadcrumb, Columns, Heading, Level, Notification } from 'react-bulma-components'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { ErrorNotification } from '../../components/ErrorNotification'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Paragraph } from '../../components/Paragraph'
import { Text } from '../../components/Text'
import { TodoItemCard, TodoItemCreateRootModal } from '../../components/Todo'
import { useFetch } from '../../hooks'
import { getTodoItemsReq } from '../../requests/todo-item'
import { GetTodoItemsResult, TodoItem } from '../../types/dto/todo-item'

const TodoMainPage: FC = () => {
  const [todoItems, setTodoItems] = useState<TodoItem[]>([])
  const [itemSizes, setItemSizes] = useState<Record<string, number>>({})
  const [showCreateModal, setShowCreateModal] = useState(false)

  const itemsFetch = useFetch<GetTodoItemsResult>(...getTodoItemsReq(1, 10, ['TitleAsc']))

  const onShowCreateModal = useCallback(() => setShowCreateModal(true), [])
  const onCloseCreateModal = useCallback(() => setShowCreateModal(false), [])

  const onRefetch = useCallback(() => {
    if (itemsFetch.state === 'idle') {
      setTodoItems([])
      setItemSizes({})
      itemsFetch.refetch(...getTodoItemsReq())
    }
  }, [itemsFetch])

  useEffect(() => {
    document.title = 'Todo-kohteet - Muikku'
    document.documentElement.lang = 'fi'
  }, [])

  useEffect(() => {
    if (itemsFetch.state === 'success') {
      setTodoItems(todoItems.concat(itemsFetch.data.items))
      setItemSizes({ ...itemSizes, ...itemsFetch.data.sizes })
      itemsFetch.reset()
    }

    if (itemsFetch.state === 'idle' && itemsFetch.data) {
      const { items, pageNumber, pageSize } = itemsFetch.data

      if (items.length === pageSize) {
        itemsFetch.refetch(...getTodoItemsReq(pageNumber + 1, pageSize))
      }
    }
  }, [itemSizes, itemsFetch, todoItems])

  const loading = itemsFetch.state === 'loading'
  const error = itemsFetch.state === 'error'

  return (
    <Block className="mt-3">
      <Breadcrumb separator="succeeds">
        <Breadcrumb.Item>
          <Link to="/todo">Etusivu</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link to="/todo/items">Todo-kohteet</Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Block className="mt-2r pb-6">
        <Level renderAs="div">
          <Level.Side align="left">
            <Heading>Todo-kohteet</Heading>
          </Level.Side>
          <Level.Side align="right" className="mr-4">
            <Button color="info" onClick={onShowCreateModal}>
              <FontAwesomeIcon icon={faPlus} />
              <Text className="ml-2">Lisää uusi kohde</Text>
            </Button>
          </Level.Side>
        </Level>

        <Block className="mt-4">
          {!error && !loading && todoItems.length === 0 && (
            <Notification color="info" light>
              <Paragraph>Et ole vielä lisännyt yhtään kohdetta.</Paragraph>
            </Notification>
          )}

          {!error && !loading && todoItems.length > 0 && (
            <Columns className="mt-5 mb-5">
              {todoItems.map((item) => (
                <Columns.Column key={item.itemId} size="one-third">
                  <TodoItemCard item={item} size={itemSizes[item.itemId] ?? 0} />
                </Columns.Column>
              ))}
            </Columns>
          )}

          {error && <ErrorNotification />}
          {loading && <LoadingSpinner />}
        </Block>
      </Block>

      <TodoItemCreateRootModal onRefetch={onRefetch} onClose={onCloseCreateModal} show={showCreateModal} />
    </Block>
  )
}

export { TodoMainPage }
