import React, { FC } from 'react'
import { Block, Box, Heading } from 'react-bulma-components'
import { Link } from 'react-router-dom'

import { Text } from '../Text'
import { TodoItem } from '../../types/dto/todo-item'

type TodoItemCardProps = {
  item: TodoItem
  size: number
}

const TodoItemCard: FC<TodoItemCardProps> = (props) => {
  const { item, size } = props

  const updatedAt = new Date(item.updatedAt ?? item.createdAt)
  const updatedDate = updatedAt.toLocaleDateString('fi')
  const updatedTime = updatedAt.toLocaleTimeString('fi', { timeStyle: 'short' })

  return (
    <Box>
      <Heading renderAs="h2" size={4} className="mb-1">
        <Link to={`/todo/items/${item.itemId}`}>{item.title}</Link>
      </Heading>
      <Block>
        <Text>{size} kohdetta</Text>
      </Block>
      <Block className="mt-3">
        <Text className="has-text-grey" style={{ fontSize: '0.9rem' }}>
          Muokattu viim. {updatedDate} {updatedTime}
        </Text>
      </Block>
    </Box>
  )
}

export { TodoItemCard }
