import React, { CSSProperties, FC, ReactNode } from 'react'

type ParagraphProps = {
  children: ReactNode
  className?: string
  style?: CSSProperties
}

const Paragraph: FC<ParagraphProps> = (props) => {
  const { children, className = '', style } = props

  return (
    <p className={className} style={style}>
      {children}
    </p>
  )
}

export { Paragraph }
