import React, { FC, useEffect } from 'react'
import { Block, Container, Heading } from 'react-bulma-components'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFish } from '@fortawesome/free-solid-svg-icons'

import { Footer } from '../../components/Footer'
import { Paragraph } from '../../components/Paragraph'

const ExpiredPage: FC = () => {
  useEffect(() => {
    document.title = 'Istunto vanhentunut'
    document.documentElement.lang = 'fi'
  }, [])

  return (
    <>
      <header>
        <Container breakpoint="max">
          <Block className="pt-4 pb-4" display="flex" alignItems="center" justifyContent="center">
            <FontAwesomeIcon icon={faFish} style={{ fontSize: '10rem' }} />
          </Block>
        </Container>
      </header>
      <main>
        <Container breakpoint="max">
          <Block display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Heading>Istunto vanhentunut</Heading>
            <Paragraph>
              Istuntosi on vanhentunut ja sinut on kirjattu automaattisesti ulos. Tunnistaudu uudelleen&nbsp;
              <Link to="/">etusivun</Link>
              &nbsp;kautta.
            </Paragraph>
          </Block>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export { ExpiredPage }
